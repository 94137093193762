import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../shared/Cookie";

axios.defaults.baseURL = "https://lkj99.kr";
axios.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
  "is_login"
)}`;

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isInApp: false,
    appPlatform: "ios" | "android",
  },
  reducers: {
    setIsInApp: (state, action) => {
      state.isInApp = action.payload;
    },
    setAppPlatform: (state, action) => {
      state.appPlatform = action.payload;
    },
  },
});

export const { setIsInApp, setAppPlatform } = commonSlice.actions;

export default commonSlice.reducer;
