import { thinkNoteRequest, request } from "./request";

// eslint-disable-next-line import/no-anonymous-default-export
export default async ({
  method,
  url,
  queryParams,
  requestBody,
  authorized = true,
}) => {
  const instance = thinkNoteRequest.getInstance(!authorized);
  return await request({ instance, method, url, queryParams, requestBody });
};
