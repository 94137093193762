import { IconContainer } from "./Icon";
import SailFill from "../../static/icons/sail-fill.png";
import SailOutline from "../../static/icons/sail-outline.png";

const FillIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={SailFill} />;
};

const OutlineIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={SailOutline} />;
};

export default {
  FillIcon,
  OutlineIcon,
};
