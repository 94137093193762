import { IconContainer } from "./Icon";
import BookShelfFill from "../../static/icons/bookshelf-fill.png";
import BookShelfOutline from "../../static/icons/bookshelf-outline.png";

const FillIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={BookShelfFill} />;
};

const OutlineIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={BookShelfOutline} />;
};

export default {
  FillIcon,
  OutlineIcon,
};
