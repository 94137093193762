import styled from "styled-components";
import { useState } from "react";
import { history } from "../redux/configStore";
import LoginModal from "../shared/LoginModal";
import SailIcon from "./Icons/SailIcon";
import PenIcon from "./Icons/PenIcon";
import ThinkIcon from "./Icons/ThinkIcon";
import BookShelfIcon from "./Icons/BookShelfIcon";
import { useSelector } from "react-redux";

const navigationContents = [
  {
    icon: <PenIcon.OutlineIcon size={24} />,
    selectedIcon: <PenIcon.FillIcon size={24} />,
    text: "오늘의 낙서",
    path: "/",
    needAuth: false,
  },
  {
    icon: <SailIcon.OutlineIcon size={24} />,
    selectedIcon: <SailIcon.FillIcon size={24} />,
    text: "생각의 바다",
    path: "/community",
    needAuth: false,
  },
  {
    icon: <ThinkIcon.OutlineIcon size={24} />,
    selectedIcon: <ThinkIcon.FillIcon size={24} />,
    text: "너의 생각",
    path: "/friends",
    needAuth: true,
  },
  {
    icon: <BookShelfIcon.OutlineIcon size={24} />,
    selectedIcon: <BookShelfIcon.FillIcon size={24} />,
    text: "나의 책장",
    path: "/mybook",
    needAuth: true,
  },
];

export default function BottomNavigation() {
  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
  const selectedPath = useSelector((state) => state.router.location.pathname);
  const is_login = useSelector((state) => state.user.is_login);

  if (
    !navigationContents.map((v) => v.path).includes(selectedPath)
  ) {
    return null;
  }

  return (
    <>
      <Container>
        {navigationContents.map(
          ({ icon, selectedIcon, text, path, needAuth }) => {
            const isSelected = selectedPath === path;

            const handleClick = () => {
              if (needAuth && !is_login) {
                setIsLoginModalOpened(true);
                return;
              }
              if (isSelected) return;

              setIsLoginModalOpened(false);

              history.push(path);
            };

            return (
              <NavItem onClick={handleClick}>
                {isSelected ? selectedIcon : icon}
                <NavText>{text}</NavText>
              </NavItem>
            );
          }
        )}
      </Container>
      {isLoginModalOpened && (
        <LoginModal close={() => setIsLoginModalOpened(false)} />
      )}
      <EmptySpace />
    </>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  z-index: 1000;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 4px;
`;

const NavText = styled.span`
  font-size: 12px;
`;

const EmptySpace = styled.div`
  width: 100%;
  height: 64px;
`;
