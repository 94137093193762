import React, { Fragment, useEffect, useState } from "react";
import { CommunityQnA } from "../components/Community/communityindex";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { api as communityActions, resetAll } from "../redux/modules/community";
import Loader from "react-loader-spinner";
import { setLoading } from "../redux/modules/community";
import ReplayIcon from "@material-ui/icons/Replay";
import { CardModal } from "../components/Community/communityindex";
import { api as commentActions } from "../redux/modules/comment";
import { history } from "../redux/configStore";
import useIsInApp from "../hooks/useIsInApp";
import { throttle } from "lodash";

const REFRESH_DISTANCE = 200;

const Community = () => {
  const dispatch = useDispatch();
  const question_list = useSelector((state) => state.community.question);
  const is_loading = useSelector((state) => state.community.is_loading);
  const isInApp = useIsInApp();
  const [cardModal, setCardModal] = useState(false);
  const [refreshOpacity, setRefreshOpacity] = useState(0);

  const touchStartScrollStart = React.useRef(null);

  useEffect(() => {
    dispatch(communityActions.communityQuestionAX());
    return () => {
      dispatch(setLoading(true));
    };
  }, []);

  const openCard = (a) => {
    if (window.innerWidth <= 750) {
      dispatch(resetAll());
      history.push(`/carddetail/${a.answerId}`);
      return;
    }
    const type = "community";
    setCardModal(true);
    dispatch(resetAll());
    dispatch(communityActions.getCardDetail(a.answerId, type));
    dispatch(communityActions.getLikeList(a.answerId));
    dispatch(commentActions.getCommentAX(a.answerId));
  };

  const closeCardModal = () => {
    setCardModal(false);
  };

  const onTouchStart = (event) => {
    touchStartScrollStart.current = event.touches[0].clientY;
  };

  const onTouchEnd = (event) => {
    if (
      event.changedTouches[0].clientY - touchStartScrollStart.current >
        REFRESH_DISTANCE &&
      !is_loading &&
      isInApp
    ) {
      dispatch(communityActions.communityQuestionAX());
      dispatch(setLoading(true));
    }
  };

  useEffect(() => {
    if (isInApp) {
      const handleOpacity = throttle(() => {
        const opacity = -window.scrollY / 100;
        if (opacity > 1) setRefreshOpacity(1);
        else setRefreshOpacity(opacity);
      }, 200);

      window.addEventListener("scroll", handleOpacity);
    }
  }, [isInApp]);

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      style={{ width: "100%" }}
    >
      {isInApp && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            padding: "30px 0px",
            top: "-50px",
          }}
        >
          <PullToRequestRefreshAnimation
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              backgroundColor: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: refreshOpacity,
            }}
          >
            <ReplayIcon fontSize="medium" style={{ color: "white" }} />
          </PullToRequestRefreshAnimation>
        </div>
      )}
      {cardModal ? <CardModal close={closeCardModal} /> : null}
      {!isInApp &&
        (is_loading ? (
          <CommunityBtnIcon style={{ paddingTop: "5px" }}>
            <Loader type="TailSpin" color="white" height={30} width={30} />
          </CommunityBtnIcon>
        ) : (
          <CommunityBtnIcon
            onClick={() => {
              dispatch(communityActions.communityQuestionAX());
              dispatch(setLoading(true));
            }}
          >
            <ReplayIcon fontSize="large" style={{ color: "white" }} />
          </CommunityBtnIcon>
        ))}

      <CommunityBtn
        onClick={() => {
          dispatch(communityActions.communityQuestionAX());
          dispatch(setLoading(true));
        }}
      >
        {" "}
        새로 고침
      </CommunityBtn>
      <CommunityContainer>
        {is_loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="Oval" color="#000000" height={100} width={100} />
          </div>
        ) : (
          <CommunityBox>
            {question_list !== 0
              ? question_list.map((q) => {
                  return <CommunityQnA key={q.id} {...q} openCard={openCard} />;
                })
              : null}
          </CommunityBox>
        )}
      </CommunityContainer>
    </div>
  );
};

const CommunityBtnIcon = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  right: 26px;
  bottom: 100px;
  width: 63px;
  height: 63px;
  border-radius: 50px;
  background: black;
  z-index: 60;
  cursor: pointer;
  box-shadow: 0px 0px 20px #0000001a;
  @media (max-width: 500px) {
    width: 50px;
    height: 50px;
    right: 14px;
    bottom: 80px;
  }
`;

const CommunityBtn = styled.div`
  position: fixed;
  display: flex;
  padding-left: 20px;
  font-family: Noto Sans CJK KR;
  font-weight: bold;
  align-items: center;
  right: 34px;
  bottom: 102px;
  width: 140px;
  height: 58px;
  border-radius: 50px;
  background: white;
  z-index: 50;
  cursor: pointer;
  box-shadow: 0px 0px 20px #0000001a;
  @media (max-width: 500px) {
    display: none;
  }
`;

const CommunityBox = styled.div`
  margin: 50px 0px 0px 0px;
  width: 100%;
  max-width: 1200px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  @media (max-width: 900px) {
    margin: 50px 0px 0px 0px;
  }
  @media (max-width: 500px) {
    margin: 50px 0px 0px 0px;
  }
  ::-webkit-scrollbar {
    display: none;
    width: 10px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    display: none;
    background: none; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    display: none;
    background-color: #ffffff; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
  @media (max-width: 750px) {
    margin: 70px 0px 0px 0px;
  }
`;

const CommunityContainer = styled.div`
  z-index: 2;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
    z-index: 20;
  }
  @media (max-width: 750px) {
    height: 100%;
  }
`;

const PullToRequestRefreshAnimation = styled.div`
  animation: rotate_image 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  transform-origin: 50% 50%;

  @keyframes rotate_image {
    100% {
      transform: rotate(-360deg);
    }
  }
`;

export default Community;
