import axios from "axios";
import { getCookie } from "../shared/Cookie";
import qs from "qs";

export const axiosUtils = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  AXIOS_CONFIG_DEFAULT: {
    timeout: 200000,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "brackets" }),
    withCredentials: false,
  },
};

export const createAxiosInstance = (axiosConfig) => {
  const instance = axios.create(axiosConfig);
  instance.interceptors.request.use(
    async (config) => {
      const cookie = getCookie("is_login");
      if (cookie) {
        config.headers.Authorization = `Bearer ${cookie}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return instance;
};

export const request = async ({
  instance,
  method,
  url,
  queryParams,
  requestBody,
}) => {
  switch (method) {
    case axiosUtils.GET:
      return await instance.get(url, { params: queryParams });
    case axiosUtils.POST:
      return await instance.post(url, requestBody, { params: queryParams });
    case axiosUtils.PUT:
      return await instance.put(url, requestBody, { params: queryParams });
    case axiosUtils.DELETE:
      return await instance.delete(url);
    default:
      return null;
  }
};

export class AppAxiosInstance {
  baseURL;
  type;
  instance;
  unauthorizedInstance;

  constructor(type) {
    this.type = type;
    switch (type) {
      case "thinknote":
        this.baseURL = "https://lkj99.kr";
        break;
      default:
        this.baseURL = "https://lkj99.kr";
    }
  }

  createInstance = () => {
    const { baseURL } = this;
    const axiosConfig = {
      ...axiosUtils.AXIOS_CONFIG_DEFAULT,
      baseURL,
    };
    return createAxiosInstance(axiosConfig);
  };

  getInstance = () => {
    if (!this.instance) {
      this.instance = this.createInstance();
    }
    return this.instance;
  };
}

export const thinkNoteRequest = new AppAxiosInstance("thinknote");
