import styled, { css } from "styled-components";

export const IconContainer = styled.img`
  ${(p) => {
    return css`
      ${getSize(p.size)};
    `;
  }};

  display: inline-block;
  vertical-align: middle;
`;

function getSize(size) {
  return css`
    min-width: ${size}px;
    width: ${size}px;
    max-width: ${size}px;
    min-height: ${size}px;
    height: ${size}px;
    max-height: ${size}px;
  `;
}
