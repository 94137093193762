import { IconContainer } from "./Icon";
import ThinkFill from "../../static/icons/think-fill.png";
import ThinkOutline from "../../static/icons/think-outline.png";

const FillIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={ThinkFill} />;
};

const OutlineIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={ThinkOutline} />;
};

export default {
  FillIcon,
  OutlineIcon,
};
