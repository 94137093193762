import { IconContainer } from "./Icon";
import PenFill from "../../static/icons/pen-fill.png";
import PenOutline from "../../static/icons/pen-outline.png";

const FillIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={PenFill} />;
};

const OutlineIcon = ({ size = 24 }) => {
  return <IconContainer size={size} src={PenOutline} />;
};

export default {
  FillIcon,
  OutlineIcon,
};
