import React, { useEffect, useState } from "react";
import logoImages from "../static/images/logo.png";
import NaverIcon from "../static/images/naver.png";
import AppleIcon from "../static/images/apple.svg";
import GoogleIcon from "../static/images/google.png";
import KaKaoIcon from "../static/images/kakao.png";
import styled from "styled-components";
import useIsInApp from "../hooks/useIsInApp";
import { messenger } from "../utils/webview";
import { setCookie } from "./Cookie";
import thinknoteApi from "../network/thinknoteApi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { api as userActions } from "../redux/modules/user";
import { Input } from "@material-ui/core";
import { history } from "../redux/configStore";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@!%*#?&])[A-Za-z\d@!%*#?&]{8,}$/;

const LoginModal = (props) => {
  const isInApp = useIsInApp();
  const appPlatform = useSelector((state) => state.common.appPlatform);
  const dispatch = useDispatch();
  const [isLoginInputView, setIsLoginInputView] = useState();
  const [isSignUpView, setIsSignUpView] = useState();
  const [email, setEmail] = useState();
  const [pw, setPw] = useState();
  const [signUpEmail, setSignUpEmail] = useState();

  const [signUpPw, setSignUpPw] = useState();

  const [signUpPwCheck, setSignUpPwCheck] = useState();
  const [privacyCheck, setPrivacyCheck] = useState(true);

  const handleLogin = (socialType) => () => {
    if (isInApp && socialType === "apple") {
      messenger.postMessage("socialLogin", { socialType });
      return;
    }
    switch (socialType) {
      case "kakao":
        window.location.href = "http://lkj99.kr/auth/kakao";
        break;
      case "naver":
        window.location.href = "http://lkj99.kr/auth/naver";
        break;
      case "google":
        window.location.href = "http://lkj99.kr/auth/google";
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isInApp) {
      const handleSocialLoginResult = (event) => {
        const uid = event.detail.uid;
        const fcm = event.detail.fcm;
        const socialType = event.detail.socialType;

        thinknoteApi({
          method: "post",
          url: "/auth/app/login",
          requestBody: { uid, fcm, socialType },
        }).then((res) => {
          setCookie("is_login", res.data.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.token}`;
          dispatch(userActions.LoginCheckAX());
        });

        props.close();
      };
      window.addEventListener("socialLoginResult", handleSocialLoginResult);

      return () => {
        window.removeEventListener(
          "socialLoginResult",
          handleSocialLoginResult
        );
      };
    }
  }, [isInApp]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePwChange = (e) => {
    setPw(e.target.value);
  };

  const handleEmailLogin = () => {
    if (!emailRegex.test(email)) {
      alert("이메일 형식이 올바르지 않습니다.");
      return;
    }

    thinknoteApi({
      method: "post",
      url: "/auth/common/login",
      requestBody: { id: email, pw },
    })
      .then((res) => {
        setCookie("is_login", res.data.token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
        dispatch(userActions.LoginCheckAX());
      })
      .catch((err) => {
        alert("아이디 혹은 비밀번호가 일치하지 않습니다.");
      });

    props.close();
  };

  const handleCheckInputChange = (e) => {
    setPrivacyCheck(e.target.checked);
  };

  const handleSignUp = async () => {
    if (!privacyCheck) {
      alert("개인정보처리방침에 동의해주세요.");
      return;
    }
    if (!emailRegex.test(signUpEmail)) {
      alert("이메일 형식이 올바르지 않습니다.");
      return;
    }
    if (!pwRegex.test(signUpPw)) {
      alert("비밀번호는 8자 이상, 영문, 숫자, 특수문자를 포함해야 합니다.");
      return;
    }
    if (signUpPw !== signUpPwCheck) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      await thinknoteApi({
        method: "post",
        url: "/auth/common/join",
        requestBody: { id: signUpEmail, pw: signUpPw },
      });
      const res = await thinknoteApi({
        method: "post",
        url: "/auth/common/login",
        requestBody: { id: signUpEmail, pw: signUpPw },
      });

      setCookie("is_login", res.data.token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;
      dispatch(userActions.LoginCheckAX());
      props.close();
    } catch (e) {
      if (e.response.data.message === "이미 존재") {
        alert("이미 존재하는 이메일입니다.");
        return;
      }
      alert("회원가입에 실패하였습니다.");
    }
  };

  return (
    <React.Fragment>
      <Background onClick={props.close} />
      <LoginBox>
        <Logo src={logoImages} />
        {isSignUpView ? (
          <>
            <Header>회원가입</Header>
            <MobileHeader>두들링</MobileHeader>
            <InputWrapper>
              <div>
                <StyledInput
                  placeholder="이메일"
                  onChange={(e) => {
                    setSignUpEmail(e.target.value);
                  }}
                  type="text"
                />
              </div>
              <StyledInput
                placeholder="비밀번호"
                onChange={(e) => {
                  setSignUpPw(e.target.value);
                }}
                type="password"
              />

              <StyledInput
                placeholder="비밀번호 확인"
                onChange={(e) => setSignUpPwCheck(e.target.value)}
                type="password"
              />

              <Button
                onClick={handleSignUp}
                style={{ width: "100%", cursor: "pointer" }}
              >
                회원가입
              </Button>

              <p>
                * 비밀번호는 8자 이상, 영문, 숫자, 특수문자를 포함해야 합니다.
              </p>
              <label htmlFor="privacy">
                <input
                  id="privacy"
                  type="checkbox"
                  checked={privacyCheck}
                  onChange={handleCheckInputChange}
                  style={{ marginRight: "6px" }}
                />
                개인정보처리방침에 동의합니다.
              </label>
              <div
                style={{
                  color: "skyblue",
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.notion.so/6ebd8a6f7ce64c4983f650312ea5f034?pvs=4",
                      "_blank",
                      "noopener noreferrer"
                    )
                  }
                >
                  개인정보처리방침
                </span>
              </div>
            </InputWrapper>
          </>
        ) : (
          <>
            <Header>로그인</Header>
            <MobileHeader>두들링</MobileHeader>
            {isLoginInputView ? (
              <InputWrapper>
                <StyledInput
                  placeholder="이메일"
                  onChange={handleEmailChange}
                  type="email"
                  value={email}
                />
                <StyledInput
                  placeholder="비밀번호"
                  onChange={handlePwChange}
                  type="password"
                  value={pw}
                />
                <Button onClick={handleEmailLogin}>로그인</Button>
                <div>
                  아직 가입하지 않았다면?{" "}
                  <button
                    onClick={() => setIsSignUpView(true)}
                    style={{
                      textDecoration: "underline",
                      color: "skyblue",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    회원가입
                  </button>
                </div>
              </InputWrapper>
            ) : (
              <>
                <MobileBodyText>
                  5분만에 완성되는 나만의 책장 <br />
                  로그인 시 이용 가능합니다.
                </MobileBodyText>
                <ButtonContainer>
                  <Button
                    style={{ background: "#FAE100", border: "none" }}
                    onClick={handleLogin("kakao")}
                  >
                    <ButtonIcon src={KaKaoIcon} />
                    <ButtonText>카카오로 로그인</ButtonText>
                  </Button>
                  {isInApp && appPlatform === "ios" && (
                    <Button
                      style={{
                        background: "#000000",
                        marginTop: "0",
                        marginBottom: "0",
                        paddingRight: "15px",
                      }}
                      onClick={handleLogin("apple")}
                    >
                      <span style={{ marginLeft: "-4px" }}>
                        <svg
                          width="50px"
                          height="50px"
                          viewBox="0 0 50 50"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                          <title>White Logo Square </title>
                          <desc>Created with Sketch.</desc>
                          <g
                            id="White-Logo-Square-"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <path
                              d="M28.2226562,20.3846154 C29.0546875,20.3846154 30.0976562,19.8048315 30.71875,19.0317864 C31.28125,18.3312142 31.6914062,17.352829 31.6914062,16.3744437 C31.6914062,16.2415766 31.6796875,16.1087095 31.65625,16 C30.7304687,16.0362365 29.6171875,16.640178 28.9492187,17.4494596 C28.421875,18.06548 27.9414062,19.0317864 27.9414062,20.0222505 C27.9414062,20.1671964 27.9648438,20.3121424 27.9765625,20.3604577 C28.0351562,20.3725366 28.1289062,20.3846154 28.2226562,20.3846154 Z M25.2929688,35 C26.4296875,35 26.9335938,34.214876 28.3515625,34.214876 C29.7929688,34.214876 30.109375,34.9758423 31.375,34.9758423 C32.6171875,34.9758423 33.4492188,33.792117 34.234375,32.6325493 C35.1132812,31.3038779 35.4765625,29.9993643 35.5,29.9389701 C35.4179688,29.9148125 33.0390625,28.9122695 33.0390625,26.0979021 C33.0390625,23.6579784 34.9140625,22.5588048 35.0195312,22.474253 C33.7773438,20.6382708 31.890625,20.5899555 31.375,20.5899555 C29.9804688,20.5899555 28.84375,21.4596313 28.1289062,21.4596313 C27.3554688,21.4596313 26.3359375,20.6382708 25.1289062,20.6382708 C22.8320312,20.6382708 20.5,22.5950413 20.5,26.2911634 C20.5,28.5861411 21.3671875,31.013986 22.4335938,32.5842339 C23.3476562,33.9129053 24.1445312,35 25.2929688,35 Z"
                              id=""
                              fill="#FFFFFF"
                              fill-rule="nonzero"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      <ButtonText style={{ color: "#ffffff", margin: 0 }}>
                        애플로 로그인
                      </ButtonText>
                    </Button>
                  )}
                  {!isInApp && (
                    <Button
                      style={{
                        background: "#FFFFFF",
                        marginTop: "0",
                        marginBottom: "0",
                      }}
                      onClick={handleLogin("google")}
                    >
                      <ButtonIcon src={GoogleIcon} />
                      <ButtonText>구글로 로그인</ButtonText>
                    </Button>
                  )}
                  <EmailLoginButton
                    onClick={() => setIsLoginInputView(true)}
                    isInApp={isInApp}
                  >
                    <ButtonText>이메일로 로그인</ButtonText>
                  </EmailLoginButton>
                </ButtonContainer>
              </>
            )}
          </>
        )}
      </LoginBox>
    </React.Fragment>
  );
};

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.4;
  height: 100vh;
  width: 100vw;
  background-color: black;
  z-index: 600;
`;

const LoginBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 380px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 610;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    width: 280px;
  }
`;

const Logo = styled.img`
  width: 140px;
  height: 140px;
  object-fit: cover;
  margin-top: 45px;
  @media (max-width: 500px) {
    display: none;
  }
`;
const MobileHeader = styled.div`
  font: normal normal bold 20px/29px Noto Sans CJK KR;
  margin-top: 23px;
  @media (max-width: 500px) {
    display: none;
  }
`;

const MobileBodyText = styled.div`
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  text-align: center;
  margin-top: 10px;
  @media (max-width: 500px) {
    display: none;
  }
`;

const Header = styled.div`
  font: normal normal bold 16px/20px Sans CJK KR;
  color: #121212;
  margin-top: 22px;
  margin-bottom: 22px;
  @media (min-width: 500px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
  @media (max-width: 500px) {
  }
`;

const Button = styled.button`
  height: 46px;
  width: 290px;
  padding: 8px 0;
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  border: 0.699999988079071px solid #d3d3d3;
  box-sizing: border-box;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    width: 200px;
    height: 46px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;
const ButtonIcon = styled.img`
  width: 40px;
  height: auto;
  padding: 0px 5px;
  @media (max-width: 500px) {
  }
`;
const ButtonText = styled.div`
  text-align: center;

  font: normal normal normal 16px/24px Noto Sans CJK KR;
  @media (max-width: 500px) {
    font: normal normal medium 14px/20px Sans CJK KR;
  }
`;

const EmailLoginButton = styled(Button)`
  @media (max-width: 500px) {
    margin-top: ${(p) => (p.isInApp ? 12 : 48)}px;
  }
`;

const InputWrapper = styled.div`
  padding: 24px;
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 46px;
  padding: 8px;
  margin-top: 48px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    width: 200px;
    height: 46px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export default LoginModal;
